const routes = [
    { 
      path: "*",
      redirect: '/'
    },
    {
      path: "/",
      name: 'home',
      component: resolve => require(['../views/homeView/HomeView.vue'],resolve),
      meta: { 
        title: 'HelloLesson',
        description: 'Educational marketplace: Learn 100+ activities at the comfort of your home - online or in-person. Choose from 12000+ teachers and instructors worldwide. Explore everything from Skateboarding to Mixology, Spanish language to Tennis, and more!'
      }
    },
    {
      path: '/frequently-asked',
      name: 'frequentlyasked',
      component: resolve => require(['../views/frequentlyAsked/FrequentlyAsked.vue'],resolve),
      meta: {
        title: 'Frequently asked - HelloLesson',
        description: 'HelloLesson is the leading online learning platform for students of all ages. Get answers to your frequently asked questions about our courses, tutors, and more. Learn how to get the most out of your learning experience with HelloLesson.'
      }
    },
    {
      path: '/find-lesson',
      name: 'findlesson',
      component: resolve => require(['../views/findLesson/FindLesson.vue'],resolve),
      meta: { 
        title: 'Find a Lesson - HelloLesson',
        description: 'HelloLesson is the best way to find the perfect lesson for you. With our easy-to-use search engine, you can quickly find the perfect lesson for your needs. Whether youre looking for a one-on-one lesson, a group lesson, or an online lesson, we have the perfect lesson for you. Start your search today and find the perfect lesson for you!'
      }
    },
    {
      path: '/how-works',
      name: 'howworks',
      component: resolve => require(['../views/howWorks/HowWorks.vue'],resolve),
      meta: {
        title: 'How it Works - HelloLesson',
        description: 'HelloLesson is an online platform that connects students with experienced tutors for personalized, one-on-one tutoring. Learn how our platform works and how it can help you achieve your academic goals.'
      }
      
    },
    {
      path: '/teach-lesson',
      name: 'teachlesson',
      component: resolve => require(['../views/teachLesson/TeachLesson.vue'],resolve),
      meta: {
        title: 'Teach a Lesson - HelloLesson',
        description: 'HelloLesson is the leading online platform for connecting teachers and students. Our mission is to make learning easier and more accessible for everyone. With our platform, teachers can create and share lessons, and students can find the perfect teacher for their needs. Whether youre looking for a tutor, a mentor, or a teacher for a specific subject, HelloLesson has you covered. Start learning today!'
      }
    },
    {
      path: '/sport/skateboarding-lessons',
      name: 'skateboarding',
      component: resolve => require(['../views/sportsPages/skateboarding.vue'],resolve),
      meta: {
        title: 'Skateboarding Lesson - HelloLesson',
        description: 'Learn how to skateboard with HelloLessons Skateboarding Lessons! Our experienced instructors will teach you the basics of skateboarding, from how to stand on a board to how to do tricks. Get ready to shred the streets and have fun!'
      }
    },
    {
      path: '/sport/skiing-lessons',
      name: 'skiing',
      component: resolve => require(['../views/sportsPages/skiing.vue'],resolve),
      meta: {
        title: 'skiing Lesson - HelloLesson',
        description: 'Learn how to ski with HelloLessons Skiing Lessons! Our experienced instructors will help you master the basics of skiing and have you skiing down the slopes in no time. Get started today and experience the thrill of skiing!'
      }
    },
    {
      path: '/sport/archery-lessons',
      name: 'archery',
      component: resolve => require(['../views/sportsPages/archery.vue'],resolve),
      meta: {
        title: 'Archery Lesson - HelloLesson',
        description: 'Learn archery with HelloLesson! Our archery lessons are designed to help you develop your skills and become a better archer. Our experienced instructors will guide you through the basics of archery and help you improve your technique. Get started today and become an expert archer!'
      }
    },
    {
      path: '/sport/tennis-lessons',
      name: 'tennis',
      component: resolve => require(['../views/sportsPages/tennis.vue'],resolve),
      meta: { 
        title: 'Tennis Lesson - HelloLesson',
        description: 'HelloLesson offers tennis lessons for all ages and skill levels. Our experienced coaches provide personalized instruction to help you improve your technique, strategy, and overall game. Whether youre a beginner or an experienced player, our tennis lessons will help you reach your goals.'
      }
    },
    {
      path: '/language/spanish-lessons',
      name: 'spanish',
      component: resolve => require(['../views/languagePages/spanish.vue'],resolve),
      meta: { 
        title: 'Spanish Lesson - HelloLesson',
        description: 'Learn Spanish with HelloLesson! Our experienced Spanish teachers will help you improve your Spanish language skills quickly and easily. With our online Spanish lessons, you can learn Spanish grammar, pronunciation, vocabulary, and more. Start learning Spanish today!'
      }
    },
    {
      path: '/language/french-lessons',
      name: 'french',
      component: resolve => require(['../views/languagePages/french.vue'],resolve),
      meta: { 
        title: 'French Lesson - HelloLesson',
        description: 'Learn French with HelloLesson! Our experienced native French tutors will help you improve your language skills and reach your goals. Get personalized French lessons tailored to your needs and level. Start speaking French today!'
      }
    },
    {
      path: '/language/english-lessons',
      name: 'english',
      component: resolve => require(['../views/languagePages/english.vue'],resolve),
      meta: { 
        title: 'English Lesson - HelloLesson',
        description: 'Learn English with HelloLesson! Our English lessons are designed to help you improve your English language skills and build confidence in speaking, reading, writing, and understanding English. Our experienced teachers will help you reach your goals quickly and easily. Start learning English today!'
      }
    },
    {
      path: '/language/korean-lessons',
      name: 'korean',
      component: resolve => require(['../views/languagePages/korean.vue'],resolve),
      meta: { 
        title: 'Korean Lesson - HelloLesson',
        description: 'Learn Korean with HelloLesson! Our experienced native Korean teachers will help you learn the language quickly and effectively. With our online Korean lessons, you can learn to read, write, and speak Korean in no time. Start your Korean language journey today!'
      }
    },
    {
      path: '/music/drum-lessons',
      name: 'Drum',
      component: resolve => require(['../views/musicPages/drum.vue'],resolve),
      meta: { 
        title: 'Drum Lesson - HelloLesson',
        description: 'Learn to play the drums with HelloLessons online drum lessons! Our experienced instructors will help you develop your skills and technique, from beginner to advanced levels. Get started today and become a master of the drums!'
      }
    },
    {
      path: '/music/cello-lessons',
      name: 'cello',
      component: resolve => require(['../views/musicPages/cello.vue'],resolve),
      meta: { 
        title: 'Cello Lesson - HelloLesson',
        description: 'Learn to play the cello with HelloLessons online cello lessons. Our experienced instructors will help you develop your skills and technique, from beginner to advanced levels. Get started today and become a master of the cello!'
      }
    },
    {
      path: '/music/flute-lessons',
      name: 'flute',
      component: resolve => require(['../views/musicPages/flute.vue'],resolve),
      meta: { 
        title: 'Flute Lesson - HelloLesson',
        description: 'Learn to play the flute with HelloLessons online flute lessons. Our experienced instructors will help you master the basics and develop your skills. Get started today and become a master of the flute!'
      }
    },
    {
      path: '/music/violin-lessons',
      name: 'violin',
      component: resolve => require(['../views/musicPages/violin.vue'],resolve),
      meta: { 
        title: 'Violin Lesson - HelloLesson',
        description: 'Learn to play the violin with HelloLessons online violin lessons. Our experienced instructors will help you develop your skills and technique, from beginner to advanced levels. Get started today and become a master of the violin!'
      }
    },
    {
      path: '/hobbies/mixology-lessons',
      name: 'mixology',
      component: resolve => require(['../views/hobbiesPages/mixology.vue'],resolve),
      meta: { 
        title: 'Mixology Lesson - HelloLesson',
        description: 'Learn the art of mixology with HelloLessons Mixology Lessons! Our experienced mixologists will teach you the basics of cocktail making, from the history of cocktails to the techniques and tools used to make them. With our mixology lessons, youll be able to impress your friends and family with your newfound skills.'
      }
    },
    {
      path: '/hobbies/shooting-lessons',
      name: 'shooting',
      component: resolve => require(['../views/hobbiesPages/shooting.vue'],resolve),
      meta: { 
        itle: 'Shooting Lesson - HelloLesson',
        description: 'Learn how to shoot with expert shooting lessons from HelloLesson. Our experienced instructors will help you develop your shooting skills and become a better shooter. Whether youre a beginner or an experienced shooter, we have the perfect shooting lesson for you. Get started today and become a better shooter with HelloLesson!'
      }
    },
    {
      path: '/hobbies/cooking-lessons',
      name: 'cooking',
      component: resolve => require(['../views/hobbiesPages/cooking.vue'],resolve),
      meta: { 
        title: 'Cooking Lesson - HelloLesson',
        description: 'Learn how to cook delicious meals with cooking lessons from HelloLesson. Our experienced chefs will teach you the basics of cooking and help you master the art of cooking. From beginner to advanced, we have cooking lessons for everyone. Start your culinary journey today!'
      }
    },
    {
      path: '/hobbies/sewing-lessons',
      name: 'sewing',
      component: resolve => require(['../views/hobbiesPages/sewing.vue'],resolve),
      meta: { 
        title: 'Sewing Lesson - HelloLesson',
        description: 'Learn how to sew with HelloLessons online sewing lessons! Our experienced instructors will teach you the basics of sewing, from threading a needle to creating your own garments. Get started today and become a master seamstress!'
      }
    },
    {
      path: '/policies-and-refunds',
      name: 'policies',
      component: resolve => require(['../views/policies/Policies.vue'],resolve),
      meta: { 
        title: 'Policies and Refunds - HelloLesson',
        description: 'HelloLesson is the leading online platform for connecting students with experienced tutors. Our policies and refunds page outlines our commitment to providing quality tutoring services and our refund policy. Learn more about our policies and refunds today.'
      }
    },
    {
      path: '/who-we-are',
      name: 'whoWeAre',
      component: resolve => require(['../views/whoWeAre/WhoWeAre.vue'],resolve),
      meta: { 
        title: 'Who we are - HelloLesson',
        description: 'HelloLesson is an online tutoring platform that connects students with experienced tutors from around the world. Our mission is to provide quality education to students of all ages and backgrounds. With our experienced tutors, we provide personalized learning experiences that help students reach their academic goals. We offer a wide range of subjects, from math and science to language and test prep. With HelloLesson, you can find the perfect tutor for your needs.'
      }
    }
  ]

  
export default routes;