import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'

import VueMeta from 'vue-meta'

import * as VueGoogleMaps from 'vue2-google-maps'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete'

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';

// main.js
import 'material-design-icons-iconfont/dist/material-design-icons.css'

import axios from 'axios'
window.axios = require('axios');
Vue.config.productionTip = false

Vue.use(VueMeta)

Vue.use(VueGoogleMaps, {
  load: {
      key: 'AIzaSyDXXakS7U8bH_ENmlCuHCck7T37iRwlPeA',
      // This is required to use the Autocomplete plugin
      libraries: 'places', // 'places,drawing,visualization'
  },
});

Vue.use(VuetifyGoogleAutocomplete, {
  /*
    not used as loaded with component
    apiKey: key,
  */
  vueGoogleMapsCompatibility: true,
});

Vue.use(VueTelInputVuetify, {
  vuetify,
});

new Vue({
  router,
  vuetify,
  axios,
  render: h => h(App)
}).$mount('#app')

