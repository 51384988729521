<template>
  <v-app>
    <header_component />

    <v-main>
      <router-view/>
    </v-main>

    <footer_component />
    <!-- <Footer /> -->
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components:{
    header_component: () => import('./components/header/header.vue'),
    footer_component: () => import('./components/footer/footer.vue'),
  },

  watch: {
		'$route' (to, from) {
      document.title = to.meta.title || 'Other Title'

      // Set the meta description dynamically
      const metaDescription = to.meta.description || 'Default Description';
      const existingMetaDescription = document.querySelector('meta[name="description"]');

      if (existingMetaDescription) {
        existingMetaDescription.setAttribute('content', metaDescription);
      } else {
        const newMetaTag = document.createElement('meta');
        newMetaTag.setAttribute('name', 'description');
        newMetaTag.setAttribute('content', metaDescription);
        document.head.appendChild(newMetaTag);
      }
    }
    },

  data(){
    return{
    }
  },
};
</script>

<style lang="scss">

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Bold.otf')  format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-SemiBold.otf')  format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Medium.otf')  format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Regular.otf')  format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-Light.otf')  format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins';
  src: url('./assets/fonts/poppins/Poppins-ExtraLight.otf')  format('truetype');
  font-weight: 200;
  font-style: normal;
}
</style>
